<template>
	<div class="iconContainer" :style="{ width: width, height: height }">
		<component :is="icon" :color="color" />
	</div>
</template>
<script>
/**
 * Import all components from the icons directory and add them to an object.
 */
const req = require.context('@/components/core/icons', false, /\.vue$/i);

const componentLoader = {};

req.keys().forEach(filename => {
	const name = `${filename.split('.')[1].split('/')[1]}`;
	const component = req(filename).default;
	componentLoader[name] = component;
});

export default {
	name: 'Icon',
	components: { ...componentLoader },
	props: {
		name: {
			type: String,
			default: 'box',
		},
		icon: {
			type: String,
			required: true,
		},
		color: {
			type: String,
			default: '#000000',
		},
		hover: {
			type: String,
		},
		width: {
			type: String,
			default: '100%',
		},
		height: {
			type: String,
			default: 'auto',
		},
	},
};
</script>
<style lang="scss" scoped>
.iconContainer {
	display: flex;
	align-items: center;
	justify-content: center;

	svg {
		width: 100%;
		height: 100%;
	}
}
</style>
