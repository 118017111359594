<template>
	<div class="contact-modal" @click.self="$store.dispatch('toggleContact')">
		<div class="modal-container" v-if="showInnerModal">
			<div class="modal-column left">
				<div class="modal-row">
					<h1>We're eager to hear from you!</h1>
				</div>
				<div class="modal-row details">612-405-4060</div>
				<div class="modal-row details">8774 Egan Drive, Savage, MN 55378</div>
				<div class="modal-row details">info@pebblecreekch.com</div>
			</div>
			<!-- <div class="modal-column">
				<div class="close-container" @click="$store.dispatch('toggleContact')" />
				<form class="contact-form" @submit.prevent="postForm" @keypress="errors = null">
					<div class="form-row">
						<span>
							Name
						</span>
						<span class="error-text" v-if="errors && errors.name">{{ errors.name[0] }}</span>
					</div>
					<div class="form-row">
						<input type="text" v-model="form.name" />
					</div>
					<div class="form-row">
						<span>Phone</span>
						<span class="error-text" v-if="errors && errors.phone">{{ errors.phone[0] }}</span>
					</div>
					<div class="form-row">
						<input type="text" v-model="form.phone" />
					</div>
					<div class="form-row">
						<span>Email</span>
						<span class="error-text" v-if="errors && errors.email">{{ errors.email[0] }}</span>
					</div>
					<div class="form-row">
						<input type="text" v-model="form.email" />
					</div>
					<div class="form-row">
						<span>Inquiry</span>
						<span class="error-text" v-if="errors && errors.body">{{ errors.body[0] }}</span>
					</div>
					<div class="form-row">
						<textarea v-model="form.body" />
					</div>
					<div class="form-row">
						<div class="form-controls-container">
							<input type="submit" value="Send" />
							<button @click.prevent="resetForm">Reset</button>
						</div>
					</div>
				</form>
			</div> -->
		</div>
		<div v-else class="message-success">
			<h1>Inquiry Sent!</h1>
		</div>
	</div>
</template>
<script>
import axios from 'axios';

export default {
	name: 'ContactModal',
	data() {
		return {
			showInnerModal: true,
			form: {
				name: null,
				phone: null,
				email: null,
				body: null,
			},
			errors: null,
		};
	},
	methods: {
		async postForm() {
			try {
				await axios.post('/api/contact', { message: this.form });
				this.successClose();
			} catch (e) {
				this.errors = e.response.data;
			}
		},
		resetForm() {
			this.form.name = null;
			this.form.phone = null;
			this.form.email = null;
			this.form.body = null;
			this.errors = null;
		},
		successClose() {
			this.showInnerModal = false;
			setTimeout(() => {
				if (this.$store.getters.showContact) {
					this.$store.dispatch('toggleContact');
				}
			}, 2000);
		},
	},
};
</script>
<style lang="scss" scoped>
$xButton: "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='%23333'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M6 18L18 6M6 6l12 12' /%3E%3C/svg%3E";

.contact-modal {
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	z-index: 21;
	background-color: rgba(0, 0, 0, 0.755);
	display: flex;
}

.message-success {
	color: #fff;
	margin: auto;

	h1 {
		font-size: 4em;
	}
}

.modal-container {
	margin: auto;
	width: 100%;
	max-width: 800px;
	min-width: 300px;
	display: flex;
	flex-wrap: wrap;
	background-color: #eee;
	min-height: 500px;
	flex-wrap: wrap;
	padding: 10px;
	justify-content: center;
	position: relative;

	.close-container {
		position: absolute;
		top: 10px;
		right: 10px;
		width: 30px;
		height: 30px;
		background-image: url($xButton);
		cursor: pointer;
	}

	.contact-form {
		display: flex;
		flex-direction: column;
		margin: auto 10px;
		justify-content: center;
		flex: 1;
		box-sizing: border-box;
		pointer-events: none;
		opacity: 0.2;

		textarea {
			font-family: serif;
			font-size: 1em;
			resize: none;
			padding: 10px;
			min-height: 100px;
			flex: 1;
			border: none;
		}

		input[type='text'] {
			flex: 1;
			border: none;
			font-family: serif;
			font-size: 1em;
			min-height: 40px;
			padding: 0 10px;
		}

		.form-controls-container {
			display: flex;
			width: 100%;
			justify-content: center;

			input[type='submit'],
			button {
				background-color: #333;
				flex: 0 80px;
				height: 30px;
				color: #fff;
				border: none;
				display: flex;
				justify-content: center;
				align-items: center;
				text-align: center;
				padding: 0px;
				box-sizing: border-box;
				cursor: pointer;
				margin: 4px;
			}

			input[type='submit']:hover,
			button:hover {
				background-color: #444;
				transition: 0.2s;
			}
		}

		.form-row {
			margin: 4px;
			display: flex;
			flex-direction: row;
			justify-content: flex-start;
			align-items: flex-start;
		}
	}

	.modal-column {
		flex: 1;
		display: flex;
		flex-direction: column;
		padding: 4px;
		box-sizing: border-box;
	}

	.modal-column.left {
		flex: 1;
		//flex: 0 1 300px;
		min-width: 300px;
		justify-content: center;
		background-color: #555;
		color: #eee;
		background-image: url('../assets/img/contact-left.jpg');
		background-size: cover;
		background-position: center;
	}

	@media only screen and (max-width: 670px) {
		.modal-column.left {
			flex: 1;
		}
	}

	.modal-row {
		padding: 10px;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.modal-row.details {
		flex: 0;
		font-size: 1.3rem;
	}
}
</style>
