<template>
	<router-link class="nav-item" :to="{ name: destination, params: { hash: hash } }" v-if="slotted">
		<slot></slot>
	</router-link>
	<router-link class="nav-item" :to="{ name: destination, params: { hash: hash } }" v-else-if="$route.name !== destination">
		{{ label ? label : destination }}
	</router-link>
	<div class="nav-item" @click="scrollTo(hash)" v-else>
		{{ label ? label : destination }}
	</div>
</template>
<script>
export default {
	name: 'ScrollLink',
	props: {
		destination: {
			type: String,
			required: true,
		},
		hash: {
			type: String,
		},
		label: {
			type: String,
		},
		offset: {
			type: Number,
			default: -60,
		},
		slotted: {
			type: Boolean,
			default: false,
		},
	},
	methods: {
		scrollTo(id, offset = this.offset) {
			const y = document.querySelector(id).getBoundingClientRect().top + window.pageYOffset + offset;

			window.scrollTo({ top: y, behavior: 'smooth' });
		},
	},
};
</script>
<style lang="scss" scoped></style>
