import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import Gallery from '../views/Gallery.vue';

Vue.use(VueRouter);

const routes = [
	{
		path: '/',
		name: 'Home',
		component: Home,
	},
	{
		path: '/about',
		name: 'About',
		component: Home,
	},
	{
		path: '/gallery/:name?',
		name: 'Gallery',
		component: Gallery,
		children: [
			{
				path: 'image/:image',
				name: 'Image',
				component: Gallery,
			},
		],
	},
];

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
	scrollBehavior(to) {
		if (to.name !== 'Home' && to.name !== 'About') return;

		let scrollObject = {
			behavior: 'smooth',
			selector: to.params.hash,
			offset: { x: 0, y: 60 },
		};

		return scrollObject;
	},
});

export default router;
