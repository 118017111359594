<template>
	<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" :stroke="color">
		<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="{2}" d="M15 19l-7-7 7-7" />
	</svg>
</template>
<script>
export default {
	name: 'Left',
	props: ['color'],
};
</script>
