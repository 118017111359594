import axios from 'axios';
import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
	state: {
		content: null,
		showContact: false,
	},
	mutations: {
		setContent(state, content) {
			state.content = content;
		},
		setShowContact(state, value) {
			state.showContact = value;
		},
	},
	actions: {
		async getContent({ commit }) {
			try {
				let response = await axios.get('/api/content');
				commit('setContent', response.data);
			} catch (e) {
				console.log(e);
			}
		},
		toggleContact({ commit, state }) {
			if (!state.showContact) {
				const initialWidth = document.body.clientWidth;
				document.body.style.top = `-${window.scrollY}px`;
				document.body.style.position = 'fixed';
				document.body.style.width = `${initialWidth}px`;
				document.getElementById('topNav').style.width = `${initialWidth}px`;
			} else {
				const scrollY = document.body.style.top;
				document.body.style.position = '';
				document.body.style.top = '';
				document.body.style.width = '';
				document.getElementById('topNav').style.width = '';
				window.scrollTo(0, parseInt(scrollY || '0') * -1);
			}
			commit('setShowContact', !state.showContact);
		},
	},
	getters: {
		content(state) {
			return state.content;
		},
		showContact(state) {
			return state.showContact;
		},
	},
	modules: {},
});
