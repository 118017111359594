<template>
	<div class="slider-container" tabindex="1" :class="`transition-${mode}`" @keyup="handleKeyPress">
		<div class="controls-container">
			<div class="control-side left" v-if="sideNavigation">
				<div class="side-button" @click="prevSlide">
					<Icon icon="left" class="margin-auto" />
				</div>
			</div>
			<div class="control-side right" v-if="sideNavigation">
				<div class="side-button" @click="nextSlide">
					<Icon icon="right" />
				</div>
			</div>
			<div class="control-bottom" v-if="bottomNavigation">
				<div
					class="bottom-nav-button"
					v-for="(button, i) in $slots.default"
					:key="`button-${i}`"
					@click="selectSlide(i)"
					:class="{ active: i === activeSlide }"
				/>
			</div>
		</div>
		<div class="prefetch">
			<renderProp
				:data="$slots.default[slideIndex]"
				v-for="(slide, slideIndex) in this.$slots.default"
				:key="`prefetch-${slideIndex}`"
			/>
		</div>
		<transition
			class="transition-class"
			name="animation"
			:mode="mode === 'stack' ? 'in-out' : null"
			@beforeEnter="transitionStart"
			@after-leave="transitionDone"
			:duration="mode === 'stack' ? transition * 2 : transition"
			appear
		>
			<div
				class="animation-container"
				:style="{ transition: `${parseInt(transition) / 1000}s all` }"
				:class="{ right: direction === 'right', left: direction === 'left' }"
				:key="`container-${randomKey}`"
			>
				<renderProp
					v-if="$slots.default && $slots.default[activeSlide]"
					:data="$slots.default[activeSlide]"
					:key="`slide-${randomKey}`"
				/>
			</div>
		</transition>
	</div>
</template>
<script>
import Icon from '@/components/core/Icon';
/** Component to render a computed DOM node */
let renderProp = {
	name: 'renderNode',
	props: ['data'],
	render() {
		return this.data;
	},
};

export default {
	name: 'Slider',
	components: { renderProp, Icon },
	props: {
		loop: {
			type: Boolean,
			default: true,
		},
		mode: {
			type: String,
			default: 'stack',
		},
		sideNavigation: {
			type: Boolean,
			default: true,
		},
		bottomNavigation: {
			type: Boolean,
			default: true,
		},
		keyboardNavigation: {
			type: Boolean,
			default: true,
		},
		transition: {
			type: [Number, String, null],
			default: 500,
		},
		duration: {
			type: [Number, String, null],
			default: null,
		},
	},
	data() {
		return {
			activeSlide: 0,
			direction: 'right',
			lock: false,
			randomKey: null,
			interval: null,
		};
	},
	mounted() {
		this.timeoutMethod();
	},
	methods: {
		handleKeyPress(e) {
			if (this.keyboardNavigation) {
				switch (e.keyCode) {
					case 37:
						this.prevSlide();
						break;
					case 39:
						this.nextSlide();
						break;
				}
			}
		},
		getSlide(index) {
			return this.$slots.default[index] ?? null;
		},
		timeoutMethod() {
			if (this.duration) {
				clearInterval(this.interval);

				this.interval = setTimeout(() => {
					this.nextSlide();
				}, this.duration);
			}
		},
		selectSlide(index) {
			if (index > this.activeSlide) {
				this.timeoutMethod();
				this.direction = 'right';
				this.$nextTick(() => {
					this.setRandomSlideKey();
					this.activeSlide = index;
				});
			} else {
				this.timeoutMethod();
				this.direction = 'left';
				this.$nextTick(() => {
					this.setRandomSlideKey();
					this.activeSlide = index;
				});
			}
		},
		nextSlide() {
			if (!this.lock) {
				this.timeoutMethod();
				this.direction = 'right';
				this.$nextTick(() => {
					if (this.activeSlide + 1 < this.$slots.default.length) {
						this.setRandomSlideKey();
						this.activeSlide += 1;
					} else {
						if (this.loop) {
							this.setRandomSlideKey();
							this.activeSlide = 0;
						}
					}
				});
			}
		},
		prevSlide() {
			if (!this.lock) {
				this.timeoutMethod();
				this.direction = 'left';
				this.$nextTick(() => {
					if (this.activeSlide !== 0) {
						this.setRandomSlideKey();
						this.activeSlide -= 1;
					} else {
						this.setRandomSlideKey();
						if (this.loop) {
							this.setRandomSlideKey();
							this.activeSlide = this.$slots.default.length - 1;
						}
					}
				});
			}
		},
		setRandomSlideKey() {
			this.randomKey = Math.random()
				.toString(36)
				.substring(7);
		},
		transitionStart() {
			this.mode === 'slide' ? (this.lock = true) : (this.lock = false);
		},
		transitionDone() {
			this.lock = false;
		},
	},
};
</script>
<style lang="scss" scoped>
.slider-container {
	position: relative;
	width: 100%;
	height: 100%;
	overflow: hidden;
}

.prefetch {
	opacity: 0;
	width: 0;
	height: 0;
	position: fixed;
	top: 0;
	left: 0;
	pointer-events: none;
}

.controls-container {
	position: absolute;
	z-index: 10;
	height: 100%;
	top: 0;
	left: 0;
	width: 100%;
	display: flex;
	justify-content: center;
	pointer-events: none;

	.control-side {
		width: 100px;
		height: 100%;
		position: absolute;
		display: flex;
		justify-content: center;
		align-items: center;
		pointer-events: all;
	}

	.side-button {
		height: 100%;
		width: 100px;
		cursor: pointer;
		display: flex;
		justify-content: center;
		align-items: center;

		.iconContainer {
			display: none;
		}
	}

	.side-button:hover {
		background-color: rgba(255, 255, 255, 0.35);

		.iconContainer {
			display: block;
			opacity: 0.8;
		}
	}

	.control-side.right {
		right: 0;
	}

	.control-side.left {
		left: 0;
	}

	.control-bottom {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 40px;
		display: flex;
		justify-content: center;

		.bottom-nav-button {
			width: 50px;
			height: 12px;
			background-color: #000;
			border: 1px solid rgba(255, 255, 255, 0.4);
			margin: 0 10px;
			opacity: 0.5;
			cursor: pointer;
			pointer-events: all;
		}

		.bottom-nav-button.active {
			opacity: 0.8;
		}
	}
}

.transition-class {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
}

.animation-container {
	pointer-events: none;
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 0;
	opacity: 1;
	transform: translateX(0%);
	display: flex;
	justify-content: center;
	align-items: center;
	pointer-events: all;
}

.transition-slide {
	.right.animation-enter {
		transform: translateX(100%);
	}

	.left.animation-enter {
		transform: translateX(-100%);
	}

	.right.animation-leave-to {
		transform: translateX(-100%);
	}

	.left.animation-leave-to {
		transform: translateX(100%);
	}
}

.transition-stack {
	.right.animation-enter {
		transform: translateX(100%);
	}

	.left.animation-enter {
		transform: translateX(-100%);
	}
}

.transition-fade {
	.animation-enter {
		opacity: 0;
	}
}
</style>
