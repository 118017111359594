<template>
	<div class="gallery">
		<div class="spacer" />
		<div class="gallery-list" v-show="!$route.params.name">
			<div class="gallery-images">
				<RouterLink
					v-for="gallery in galleries"
					:to="{ name: 'Gallery', params: { name: gallery.name } }"
					class="gallery-image"
					:key="gallery.id"
				>
					<img :src="gallery.images[0].thumbnail" alt="gallery.name" />
					<!-- <div class="gallery-image-overlay">
						<h2>{{ gallery.name }}</h2>
					</div> -->
				</RouterLink>
			</div>
		</div>
		<div class="gallery-list" v-if="galleries && $route.params.name">
			<RouterLink :to="{ name: 'Gallery' }" class="back"
				><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#777777" class="size-6">
					<path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5" /></svg
				><span>Back</span>
			</RouterLink>
			<div class="gallery-images expanded">
				<RouterLink
					v-for="(image, i) in galleries[$route.params.name].images"
					:to="{ name: 'Image', params: { name: $route.params.name, image: i } }"
					class="gallery-image"
					:key="`image-${i}`"
				>
					<img v-if="(i + 1) % 3 === 0" :src="image.thumbnail" />
					<img else :src="image.large" />
					<div class="loading-overlay"></div>
				</RouterLink>
			</div>
		</div>
		<div v-if="openedImage?.large" class="image-modal" @click.self.stop="closeImage" @keydown.esc="closeImage">
			<div class="loading-overlay"></div>
			<img :src="openedImage.large" />
		</div>
	</div>
</template>
<script>
import axios from 'axios';
export default {
	name: 'Gallery',
	data() {
		return {
			galleries: undefined,
		};
	},
	async mounted() {
		await this.fetchGalleries();
	},
	computed: {
		openedImage() {
			if (this.$route.params.image !== undefined && this.galleries) {
				return this.galleries[this.$route.params.name].images[this.$route.params.image];
			} else {
				return undefined;
			}
		},
	},
	methods: {
		async fetchGalleries() {
			await axios.get('/api/galleries').then(res => {
				this.galleries = res.data;
			});
		},
		openImage(imageName) {
			this.$router.push({ name: 'Gallery', params: { name: this.$route.params.name, image: imageName } });
		},
		closeImage() {
			this.$router.go(-1);
		},
	},
	watch: {
		'$route.params.name': {
			handler(value) {
				if (value) window.scrollTo(0, 0);

				if (!value) this.openImage = undefined;
			},
		},
	},
};
</script>
<style lang="scss" scoped>
.gallery {
	display: flex;
	flex-direction: column;
	justify-content: center;
	max-width: 1200px;
	width: 100%;
	margin: auto;
	.spacer {
		flex: 0 100px;
	}
	.gallery-list {
		flex: 1;

		padding: 2rem;
		display: flex;
		justify-content: center;
		overflow: hidden;
		justify-content: center;
		align-items: center;
		flex-direction: column;

		.back {
			width: 100%;
			height: 28px;
			display: flex;
			text-decoration: none;
			color: #777;
			text-align: center;
			align-items: center;

			svg {
				height: 24px;
				width: 24px;
				margin: auto 0;
				transform: translateY(-2px);
			}
		}

		.gallery-images {
			display: grid;
			width: 100%;
			grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
			gap: 1rem;

			.gallery-image {
				position: relative;
				overflow: hidden;
				aspect-ratio: 3/2;
				cursor: pointer;
				background-color: #eee;
				img {
					width: 100%;
					height: auto;
					object-fit: cover;
					border-radius: 3px;
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					z-index: 1;
				}
			}

			&.expanded {
				@media (min-width: 1200px) {
					.gallery-image:nth-child(3n) {
						grid-column: span 2;
					}
				}

				img {
					transition: all 0.5s ease;
				}
			}
		}
	}

	@media (max-width: 1200px) {
		.gallery-list {
			padding: 1rem;
		}

		.gallery-image {
			width: calc(100vw - 2rem);
		}
	}

	@media (max-width: 600px) {
		.gallery-list {
			padding: 0.5rem;
		}
		.gallery-image {
			width: calc(100vw - 1rem);
		}

		.gallery-images {
			&.expanded {
				.gallery-image {
					pointer-events: none;
				}
			}
		}
	}
}

.loading-overlay {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 100%;
	height: 100%;
	max-width: 300px;
	max-height: 300px;
	display: flex;
	justify-content: center;
	align-items: center;
	&:after {
		content: ' .';
		color: white;
		animation: dots 2s steps(5, end) infinite;
		font-size: 4rem;
		top: 50%;
	}

	@keyframes dots {
		0%,
		20% {
			color: rgba(0, 0, 0, 0);
			text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
		}
		40% {
			color: white;
			text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
		}
		60% {
			text-shadow: 0.25em 0 0 white, 0.5em 0 0 rgba(0, 0, 0, 0);
		}
		80%,
		100% {
			text-shadow: 0.25em 0 0 white, 0.5em 0 0 white;
		}
	}
}

.image-modal {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: #353535f1;
	z-index: 500;
	display: flex;
	justify-content: center;
	align-items: center;

	img {
		max-width: 80%;
		max-height: 80%;
		z-index: 5;
	}
}
</style>
