<template>
	<div class="home" ref="homeContainer" id="home" v-if="$store.getters.content">
		<div class="spacer" />
		<div class="carousel-container">
			<div class="hero-logo-container">
				<div class="hero-logo">
					<img src="@/assets/img/LogoFull.svg" />
				</div>
			</div>
			<slider mode="fade" class="slider" :transition="2000" :loop="true" :duration="7000">
				<slide
					v-for="(item, i) in $store.getters.content.carousel"
					:key="`img-${i}`"
					:background-image="`/images/gallery/large/${item.carousel_itemable.file}.jpg`"
				/>
			</slider>
		</div>
		<div class="three-quarter flex section-1">
			<div class="pattern-3" />
			<div class="content">
				<h1>
					People are usually the happiest at home.
				</h1>
				<h2>
					- William Shakespeare -
				</h2>
				<p>
					At Pebble Creek Custom Homes, we understand this notion and we know that your new home will be the backdrop for a
					lifetime of happy memories. Through creativity and excellence, we are determined to make your building experience as
					enjoyable as the time you'll spend in your future home.
				</p>
			</div>
		</div>
		<div class="half-page" id="about">
			<div class="about-content">
				<div class="pattern-4" />
				<div class="content-text" v-html="home.sections['About1'].content" />
			</div>
			<div
				class="flex-one about-image"
				v-if="$store.getters.content.aboutImages[0]"
				:style="{ backgroundImage: `url(/images/gallery/large/${$store.getters.content.aboutImages[0]}.jpg)` }"
			>
				<div class="pattern-2" />
			</div>
		</div>
		<div class="half-page">
			<div
				class="about-image"
				v-if="$store.getters.content.aboutImages[1]"
				:style="{ backgroundImage: `url(/images/gallery/large/${$store.getters.content.aboutImages[1]}.jpg)` }"
			>
				<div class="pattern-2" />
			</div>
			<div class="about-content">
				<div class="pattern-4" />
				<div class="content-text" v-html="home.sections['About2'].content" />
			</div>
		</div>
		<PageFooter />
	</div>
</template>

<script>
import Slide from '@/components/Slide';
import { Slider } from '@/components/slider';
import PageFooter from '@/components/PageFooter';

export default {
	name: 'Home',
	components: { Slide, Slider, PageFooter },
	watch: {
		'$route.meta.hash': {
			handler() {
				this.scrollTo(this.$route.hash);
			},
		},
	},
	computed: {
		home() {
			return this.$store.getters.content.pages.Home;
		},
	},
	methods: {
		scrollTo(target) {
			target ? (location.hash = target) : (this.$refs.homeContainer.scrollTop = 0);
		},
	},
};
</script>
<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script&family=Parisienne&display=swap');
@import '~@/styles/_variables.scss';

.home {
	width: 100%;
	position: relative;
	background-color: #eee;
}

.carousel-container {
	background-position: center;
	width: 100%;
	height: calc(100vh - 60px);
	background-size: cover;
	position: relative;

	.hero-logo-container {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		pointer-events: none;
		z-index: 10;
		display: flex;

		.hero-logo {
			flex: 0 30%;
			min-width: 300px;
			margin: auto;
			position: relative;
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}
}

.section-1 {
	color: #111;
	background-color: #eee;
	background-image: url('~@/assets/img/section-1-bg.jpg');
	background-size: cover;
	background-position: center;
	position: relative;

	.content {
		margin: auto;
		flex: 0 1 $max-width;
		display: flex;
		flex-direction: column;
		justify-content: center;
		z-index: 12;
		padding: 20px;

		h1 {
			font-family: 'Dancing Script', cursive;
			font-size: 4em;
		}
		h2 {
			font-family: 'Dancing Script', cursive;
			font-size: 3em;
		}
		p {
			font-size: 1.3em;
		}
	}
}

.half-page {
	display: flex;
	height: 50vh;
	flex-wrap: wrap;

	.about-image {
		background-size: cover;
		background-position: center;
		position: relative;
		flex: 0 50%;
	}

	.about-content {
		flex: 1 300px;
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;

		.content-text {
			max-width: 500px;
			font-size: 1.2em;
			line-height: 1.5em;
			margin: 20px;
		}
	}
}

@media only screen and (max-width: 630px) {
	.section-1 {
		.content {
			h1 {
				font-family: 'Dancing Script', cursive;
				font-size: 2em;
			}
			h2 {
				font-family: 'Dancing Script', cursive;
				font-size: 1.5em;
			}
			p {
				font-size: 1em;
			}
		}
	}

	.half-page {
		.about-image {
			flex: 0 100%;
		}
		.about-content {
			flex: 1;

			.content-text {
				font-size: 1em;
				line-height: 1.2em;
				margin: 10px;
			}
		}
	}

	.half-page:nth-child(even) {
		flex-wrap: wrap-reverse;
	}
}
</style>
