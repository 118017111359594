<template>
	<div class="nav">
		<div class="nav-items-wrapper">
			<ScrollLink class="nav-logo" label="Home" destination="Home" hash="#home" :slotted="true">
				<img src="@/assets/img/Logo.svg" />
			</ScrollLink>

			<div class="nav-items">
				<ScrollLink label="Home" destination="Home" hash="#home" />
				<ScrollLink label="About" destination="About" hash="#about" />
				<RouterLink to="/gallery" class="nav-item">Gallery</RouterLink>
				<span class="nav-item" @click="$store.dispatch('toggleContact')">Contact</span>
			</div>
		</div>
	</div>
</template>
<script>
import ScrollLink from '@/components/ScrollLink';
export default {
	name: 'TopNav',
	components: { ScrollLink },
};
</script>
<style lang="scss" scoped>
.nav {
	flex: 0 74px;
	background-color: #eee;
	color: #222;
	display: flex;
	z-index: 10;
	box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.1);
	pointer-events: all;

	.nav-items-wrapper {
		flex: 0 1200px;
		display: flex;
		justify-content: space-between;
		margin: auto;
		height: 100%;

		.nav-logo {
			flex: 0 70px;
			display: flex;
			box-sizing: border-box;
			img {
				flex: 1;
				margin: auto;
				height: 90%;
				width: auto;
			}
		}

		.nav-items {
			flex: 0 30%;
			display: flex;
			justify-content: space-evenly;
			cursor: pointer;
			font-size: 1.2em;

			.nav-item {
				flex: 1;
				display: flex;
				justify-content: center;
				align-items: center;
				font-weight: 500;
				padding: 0 20px;
			}

			.nav-item:hover {
				background-color: #ffffff;
			}
		}

		a {
			color: #222;
			text-decoration: none;

			&.router-link-exact-active {
				color: #222;
			}
		}
	}
}
</style>
