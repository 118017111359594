<template>
	<div class="footer">
		<div class="footer-text">
			612-405-4060 <br />
			8774 Egan Drive, Savage, MN 55378 <br />
			info@pebblecreekch.com <br />
			License Number BC691406 <br />
		</div>
		<div class="footer-images">
			<img src="../assets/img/footer/batc.png" />
			<img src="../assets/img/footer/bbb.jpg" />
			<img src="../assets/img/footer/greenpath.png" />
			<img src="../assets/img/footer/parade.png" />
			<img src="../assets/img/footer/reggie.png" />
		</div>
	</div>
</template>
<script>
export default {
	name: 'PageFooter',
};
</script>
<style lang="scss" scoped>
.footer {
	width: 100%;
	background-color: #fff;
	display: flex;
	flex-direction: column;

	.footer-images {
		margin: auto;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-around;
		img {
			margin: 10px;
			height: 60px;
			width: auto;
		}
	}

	.footer-text {
		margin: 20px;
		color: #888;
		font-size: 0.9em;
	}
}
</style>
