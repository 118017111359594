<template>
	<div id="app">
		<transition name="contact-fade" appear>
			<ContactModal v-if="$store.getters.showContact" />
		</transition>

		<div id="topNav" class="nav-wrapper" :class="{ 'scroll-pad': $store.getters.showContact }">
			<TopNav />
		</div>

		<router-view class="view" />
	</div>
</template>
<script>
import TopNav from '@/components/TopNav';
import ContactModal from '@/components/ContactModal.vue';

export default {
	name: 'App',
	components: { TopNav, ContactModal },
	async mounted() {
		if (!this.$store.getters.content) {
			await this.$store.dispatch('getContent');
		}
	},
};
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Benne&display=swap');
@import './styles/_global.scss';

html {
	height: 100%;
}

body {
	position: relative;
	margin: 0;
	height: 100%;
}

#app {
	font-family: 'Benne', serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-align: center;
	color: #111;
	margin: none;
	display: flex;
	flex-direction: column;
	height: 100%;
	position: relative;
}

.nav-wrapper {
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	position: fixed;
	z-index: 20;
	display: flex;
	flex-direction: column;
	pointer-events: none;
}

.view-container {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	overflow-y: auto;
}

.contact-fade-enter-active,
.contact-fade-leave-active {
	transition: opacity 0.2s;
}

.contact-fade-enter,
.contact-fade-leave-to {
	opacity: 0;
}

.spacer {
	height: 60px;
}

</style>
