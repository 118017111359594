<template>
	<div class="slideContainer">
		<transition :name="!ie ? 'zoom' : 'none'">
			<div class="backgroundContainer" v-if="mounted" :style="{ backgroundImage: `url(${backgroundImage})` }"></div>
		</transition>
		<div class="pattern" />
		<div class="contentContainer">
			<slot />
		</div>
	</div>
</template>
<script>
export default {
	name: 'Slide',
	props: ['backgroundImage'],
	data() {
		return {
			mounted: false,
			ie: /MSIE|Trident/.test(window.navigator.userAgent),
		};
	},
	mounted() {
		this.mounted = true;
	},
};
</script>
<style lang="scss" scoped>
.slideContainer {
	width: 100%;
	height: 100%;
	overflow: hidden;
}

.backgroundContainer {
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	position: absolute;
	z-index: 1;
	will-change: transform;
	backface-visibility: hidden;
}

.contentContainer {
	width: 100%;
	height: 100%;
	position: absolute;
	z-index: 3;
}

.zoom-enter-active {
	transition: 10s transform linear;
}

.zoom-enter {
	transform: scale(1);
}

.zoom-enter-to {
	transform: scale(1.15);
}
</style>
